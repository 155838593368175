import axios from '@axios'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchCompetition(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/competitions/${id}/edit`)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchCompetitions(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				axios
					.get('/competitions', { params: queryParams })
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		declineTender(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/competitions/${id}/decline?fromParams=${true}`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		fetchInvitation(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/invitations/${id}/edit`)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		sendInvitationResponse(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/invitations/${id}/response`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
	}
}