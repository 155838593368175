<template>
	<div>
		<!-- Filters -->
		<competitions-list-filters
			:options="options"
			:tender-states-filter.sync="tenderStatesFilter"
		/>

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refTenderListTable"
				class="position-relative table-tenders"
				:style="totalTenders >= 1 && totalTenders <= 3 ? 'height: 280px' : 'height: inherit'"
				:items="fetchTenders"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(tender_start_date)="data">
					<span v-html="data.label"></span>
				</template>

				<template #head(is_winner)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(tender_title)="data">
					<b-media vertical-align="center">
						<div class="font-weight-bold d-block text-nowrap text-primary">{{ data.item.tender_title }}</div>
					</b-media>
				</template>

				<template #cell(tender_code)="data">
					<div class="text-nowrap">{{ data.item.tender_code }}</div>
				</template>

				<template #cell(competition_state_name)="data">
					<b-badge
						class="text-nowrap"
						:variant="`light-${data.item.competition_state_color}`"
						pill
					>{{ data.item.competition_state_name }}</b-badge>
				</template>

				<template #cell(tender_start_date)="data">
					<div
						class="text-nowrap"
					>{{ data.item.tender_start_date ? moment(data.item.tender_start_date).format('DD-MM-YYYY HH:mm') : '-' }}</div>
				</template>

				<template #cell(is_winner)="data">
					<b-badge
						class="text-nowrap"
						:variant="data.item.is_winner === 1 ? 'light-success' : data.item.is_winner === 0 ? 'light-danger' : 'light-secondary'"
						pill
					>
						{{ data.item.is_winner === 1 ? 'ADJUDICADO'
						: data.item.is_winner === 0 ? 'NO ADJUDICADO'
						: data.item.competition_state_id === EN_REVISION ? 'EN PROCESO'
						: '-' }}
					</b-badge>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item
							:to="{ name: 'competitions-invitation', params: { invitation_id: data.item.invitation_id } }"
						>
							<feather-icon icon="EyeIcon" />
							<span class="align-middle ml-50">Ver Invitación</span>
						</b-dropdown-item>

						<b-dropdown-item
							v-if="validateActions(data.item.competition_state_id)"
							:to="{ name: 'competitions-edit', params: { id: data.item.id } }"
						>
							<feather-icon
								:icon="data.item.competition_state_id === CompetitionStates.FINALIZADO ? 'EyeIcon' : 'EditIcon'"
							/>
							<span class="align-middle ml-50">
								{{ data.item.competition_state_id === CompetitionStates.FINALIZADO ? 'Ver' : 'Completar' }}
								Licitación
							</span>
						</b-dropdown-item>

						<b-dropdown-item
							v-if="validateActions(data.item.competition_state_id, true)"
							@click="declineTender(data.item.id)"
						>
							<feather-icon icon="XCircleIcon" />
							<span class="align-middle ml-50">Declinar Licitación</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalTenders"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import { CompetitionStates } from "@core/utils/data"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import CompetitionsListFilters from "./CompetitionsListFilters.vue"
import competitionStoreModule from "../competitionStoreModule"
import { avatarText } from "@core/utils/filter"
import useNotifications from "@notifications"
import useCompetitionsList from "./useCompetitionsList"
import VueSweetalert2 from "vue-sweetalert2"
import { getUserRoles } from "@/auth/utils"
import vSelect from "vue-select"
import store from "@/store"
import moment from "moment"
import axios from "@axios"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		CompetitionsListFilters,
		vSelect
	},
	data() {
		return {
			moment
		}
	},
	setup() {
		// USE TOAST
		const { swalNotification, toastNotification } = useNotifications()
		const { isBidder } = getUserRoles()

		const { INVITACION_ACEPTADA, FINALIZADO, EN_CURSO, EN_REVISION } =
			CompetitionStates

		// REFS
		const options = ref({})

		const TENDER_APP_STORE_MODULE_NAME = "app-competition"

		// REGISTER MODULE
		if (!store.hasModule(TENDER_APP_STORE_MODULE_NAME))
			store.registerModule(TENDER_APP_STORE_MODULE_NAME, competitionStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(TENDER_APP_STORE_MODULE_NAME))
				store.unregisterModule(TENDER_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			getSelectFilters()
		})

		const getSelectFilters = async () => {
			try {
				const response = await axios.get("/selectors/competition-states")

				options.value = {
					tenderStates: response.data.competitionStates
				}
			} catch (error) {
				toastNotification(error.response.data.message, false)
			}
		}

		const validateActions = (state_id, declined = false) => {
			let allowed = false
			if (declined) {
				allowed = [INVITACION_ACEPTADA, EN_CURSO].includes(state_id)
					? true
					: false
			} else {
				allowed = [FINALIZADO, EN_CURSO].includes(state_id) ? true : false
			}
			return allowed
		}

		const declineTender = (id) => {
			Vue.swal({
				title: "¿Está seguro(a) de declinar la licitación?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, declinar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (result.value) {
					store
						.dispatch("app-competition/declineTender", { id })
						.then((response) => {
							refetchData()
							swalNotification(response.data.message)
						})
						.catch((error) => {
							const message =
								error.response.data.message || "Error al declinar la licitación"
							swalNotification(message, false)
						})
				}
			})
		}

		const {
			fetchTenders,
			tableColumns,
			perPage,
			currentPage,
			totalTenders,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refTenderListTable,
			refetchData,

			// EXTRA FILTERS
			tenderStatesFilter
		} = useCompetitionsList()

		return {
			fetchTenders,
			tableColumns,
			options,
			perPage,
			currentPage,
			totalTenders,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refTenderListTable,
			refetchData,

			// EXTRA FILTERS
			tenderStatesFilter,

			// ACTIONS
			avatarText,
			declineTender,
			validateActions,

			CompetitionStates,
			EN_REVISION
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>