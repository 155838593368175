import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { useToast } from 'vue-toastification/composition';

export default function useCompetitionsList() {

	// Use toast
	const toast = useToast()

	const refTenderListTable = ref(null)

	const styleColumns = {
		thClass: 'text-center',
		tdClass: 'text-center'
	}

	// Table Handlers
	let tableColumns = [
		{ key: 'tender_title', sortable: true, label: 'LICITACIÓN', thClass: styleColumns.thClass },
		{ key: 'tender_code', sortable: true, label: 'CÓDIGO', ...styleColumns },
		{ key: 'competition_state_name', sortable: true, label: 'ESTADO', ...styleColumns },
		{ key: 'tender_start_date', sortable: true, label: 'FECHA DE <br/> INICIO', ...styleColumns },
		{ key: 'is_winner', sortable: true, label: 'RESULTADO DE<br/> ADJUDICACIÓN', ...styleColumns },
		{ key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns }
	]

	const perPage = ref(10);
	const totalTenders = ref(0);
	const currentPage = ref(1);
	const perPageOptions = [10, 25, 50, 100];
	const searchQuery = ref('');
	const sortBy = ref('id');
	const isSortDirDesc = ref(true);

	const tenderStatesFilter = ref(null);

	const dataMeta = computed(() => {
		const localItemsCount = refTenderListTable.value ? refTenderListTable.value.localItems.length : 0;
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalTenders.value,
		}
	});

	const refetchData = () => {
		refTenderListTable.value.refresh();
	};

	watch([currentPage, perPage, searchQuery, tenderStatesFilter], () => {
		refetchData();
	});

	const fetchTenders = (ctx, callback) => {
		
		store
			.dispatch('app-competition/fetchCompetitions', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				competitionStates: tenderStatesFilter.value
			})
			.then(response => {
				const { tenders, total } = response.data;
				callback(tenders);
				totalTenders.value = total;
			})
			.catch(() => {
				toast({
					component: ToastificationContent,
					props: {
						title: 'Error al obtener la lista de licitaciones',
						icon: 'AlertTriangleIcon',
						variant: 'danger'
					}
				});
			})
	};

	return {
		fetchTenders,
		tableColumns,
		perPage,
		currentPage,
		totalTenders,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refTenderListTable,

		refetchData,

		// Extra Filtersf
		tenderStatesFilter,
	}
}